<template>
  <div>
    <!-- <div class="page-header" style="margin-left: 15px; margin-botto: 10px">
      <i class="page-header-title" style="margin-right: 16px"></i>订单列表
    </div> -->

    <a-card :bordered="false">
      <!-- 我的订单 -->
      <span class="member-user-title">我的订单</span>

      <div style="margin-bottom: 20px">
        <a-form layout="inline">
          <a-form-item label="订单号">
            <a-input placeholder="请输入订单号" v-model="queryParams.orderNum">
            </a-input>
          </a-form-item>

          <a-form-item label="充值账号">
            <a-input
              placeholder="请输入充值账号"
              v-model="queryParams.rechargeAccount"
            >
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" @click="queryCriteria()"> 查询 </a-button>
          </a-form-item>
        </a-form>
      </div>

      <a-tabs :default-active-key="0" @change="handleClick">
        <a-tab-pane
          :tab="item.orderLabel"
          v-for="(item, key, index) in orderStatusList"
          :key="item.statusDelivery"
        >
          <!-- 新的 -->
          <div class="order-list">
            <div class="order-item" v-for="(item, index) in lists" :key="index">
              <div class="top">
                <!-- <span> 交易成功</span> -->
                <div class="ordersn">
                  订单号：
                  <span>
                    {{ item.serialNum }}
                  </span>
                </div>
                <div class="time">
                  下单时间：{{ item.datelineCreateReadable }}
                </div>
                <!---->
              </div>
              <div class="bottom">
                <div
                  class="goods_img"
                  @click="toProductDetails(item.orderFormProduct.productId)"
                >
                  <el-image
                    :src="item.orderFormProduct.picUrl"
                    class="order_product_img"
                  >
                  </el-image>
                </div>

                <div
                  class="goods_name"
                  @click="toProductDetails(item.orderFormProduct.productId)"
                >
                  <p>{{ item.orderFormProduct.productName }}</p>
                  <div class="send_type">
                    <a-tag :color="item.orderFormProduct.productTypeColor">
                      {{ item.orderFormProduct.productTypeText }}
                    </a-tag>
                  </div>
                </div>

                <!-- 商品价格 -->
                <div class="goods_price">
                  <div class="order_lable">
                    {{ item.orderFormProduct.productPrice }}
                  </div>
                  <span class="order_lable_name">商品单价</span>
                </div>

                <!-- 购买数量 -->
                <div class="goods_price">
                  <div class="order_lable">
                    {{ item.orderFormProduct.quantity }}
                  </div>
                  <span class="order_lable_name">购买数量</span>
                </div>

                <!-- 购买价格 -->
                <div class="goods_price">
                  <div class="order_lable">
                    {{ item.amountFinally }}
                  </div>
                  <span class="order_lable_name">订单价格</span>
                </div>

                <!-- 状态 -->
                <div class="goods_price">
                  <a-tag :color="item.statusTextColor">
                    {{ item.statusText }}
                  </a-tag>
                </div>

                <div class="btns">
                  <a-button
                    style="margin-bottom: 5px"
                    @click="getLogisticsInfo(item)"
                    type="text"
                    size="small"
                    v-if="item.orderFormDelivery"
                  >
                    物流信息
                  </a-button>

                  <a-button
                    style="margin-bottom: 5px"
                    @click="orderDetails(item.id, item)"
                    type="link"
                    v-preventClick
                  >
                    订单详情
                  </a-button>

                  <a-button
                    style="margin-bottom: 5px"
                    @click="applySh(item.id)"
                    v-if="item.paid"
                    type="text"
                    v-preventClick
                  >
                    申请售后
                  </a-button>

                  <a-button
                    style="margin-bottom: 5px"
                    type="danger"
                    @click="confirmPayment(item)"
                    v-if="!item.paid"
                    v-preventClick
                  >
                    立即付款
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <!-- 订单为空提示 -->
      <el-empty
        v-if="!lists || lists.length < 1"
        description="您还没有订单~"
      ></el-empty>

      <!-- 分页 -->
      <div style="float: right; margin-bottom: 20px">
        <pagination
          :total="total"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div>

      <!-- 订单详情弹框 -->

      <a-modal
        title="订单详情"
        :visible="orderDetailsDialog"
        @cancel="orderDetailsDialog = false"
        width="60%"
      >
        <template v-if="this.orderDetailsObj.orderFormProduct">
          <div class="order_details_title">商品信息</div>

          <a-descriptions :column="3" bordered>
            <a-descriptions-item>
              <template slot="label"> 商品名称 </template>
              <template>
                {{ orderDetailsObj.orderFormProduct.productName }}
              </template>
            </a-descriptions-item>
            <a-descriptions-item>
              <template slot="label"> 商品单价 </template>

              <template>
                ￥{{ orderDetailsObj.orderFormProduct.productPriceDeal }}
              </template>
            </a-descriptions-item>
          </a-descriptions>

          <div>
            <div v-if="this.orderDetailsObj.cardsList">
              <el-divider />

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div class="order_details_title">卡密信息</div>

                <div style="margin-bottom: 18px">
                  <el-button
                    type="primary"
                    size="small"
                    @click="cardToQrCode"
                    v-preventClick
                  >
                    生成二维码
                  </el-button>

                  <el-button
                    type="success"
                    size="small"
                    @click="allCopying()"
                    v-preventClick
                  >
                    一键复制
                  </el-button>
                </div>
              </div>

              <a-descriptions
                :column="3"
                bordered
                v-for="(item, index) in this.orderDetailsObj.cardsList"
                :key="index"
              >
                <a-descriptions-item v-if="item.cardNum">
                  <template slot="label"> 卡号</template>
                  {{ item.cardNum }}
                </a-descriptions-item>
                <a-descriptions-item v-if="item.cardPwd">
                  <template slot="label"> 卡密</template>
                  {{ item.cardPwd }}
                </a-descriptions-item>
                <a-descriptions-item v-if="item.link">
                  <template slot="label"> 兑换地址</template>
                  {{ item.link }}
                </a-descriptions-item>
                <a-descriptions-item v-if="item.endTime">
                  <template slot="label"> 过期时间</template>
                  {{ item.endTime }}
                </a-descriptions-item>
              </a-descriptions>
            </div>

            <el-divider />

            <div>
              <div class="order_details_title">充值信息</div>

              <a-descriptions :column="3" bordered>
                <a-descriptions-item
                  v-for="(item, index) in orderDetailsObj.orderParams"
                  :key="index"
                >
                  <template slot="label"> {{ item.name }} </template>

                  <!-- 图片上传 -->
                  <template v-if="item.type === '4'">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="item.value"
                    >
                    </el-image>
                  </template>

                  <span v-else>
                    {{ item.value }}
                  </span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>

          <el-divider />
          <div class="order_details_title">订单信息</div>

          <a-descriptions :column="3" bordered>
            <a-descriptions-item>
              <template slot="label"> 订单编号 </template>
              {{ orderDetailsObj.serialNum }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template slot="label"> 提交时间 </template>
              {{ orderDetailsObj.datelineCreateReadable }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template slot="label"> 订单状态 </template>
              <el-tag
                size="small"
                effect="dark"
                :color="orderDetailsObj.statusTextColor"
              >
                {{ orderDetailsObj.statusText }}
              </el-tag>
            </a-descriptions-item>

            <a-descriptions-item>
              <template slot="label"> 订单金额 </template>
              ￥{{ orderDetailsObj.amountFinally }}
            </a-descriptions-item>

            <a-descriptions-item>
              <template slot="label"> 支付金额 </template>
              ￥{{ orderDetailsObj.amountFinally }}
            </a-descriptions-item>

            <a-descriptions-item>
              <template slot="label"> 下单数量</template>
              {{ orderDetailsObj.orderFormProduct.quantity }}
            </a-descriptions-item>

            <a-descriptions-item v-if="orderDetailsObj.startNum">
              <template slot="label"> 开始数量</template>
              {{ orderDetailsObj.startNum }}
            </a-descriptions-item>

            <a-descriptions-item v-if="orderDetailsObj.nowNum">
              <template slot="label"> 当前数量</template>
              {{ orderDetailsObj.nowNum }}
            </a-descriptions-item>

            <a-descriptions-item :span="3">
              <template slot="label"> 订单备注: </template>
              {{ orderDetailsObj.deliveryRemark }}
            </a-descriptions-item>

            <a-descriptions-item :span="3">
              <template slot="label"> 充值信息: </template>
              {{ orderDetailsObj.sellerRemark }}
            </a-descriptions-item>
          </a-descriptions>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click="orderDetailsDialog = false">关 闭</el-button>
        </div>
      </a-modal>

      <Service
        :afterOrderId="orderId"
        v-if="dialogVisible"
        @close="clickClose"
      />
    </a-card>

    <!-- 查看物流信息弹框 -->
    <van-popup
      v-model="logisticsStatus"
      closeable
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div style="padding: 10px">
        <van-form v-if="orderDetails">
          <van-field
            v-model="orderDetailsObj.serialNum"
            name="订单号"
            label="订单号"
            placeholder="订单号"
          />

          <template v-if="orderDetailsObj.orderFormDelivery">
            <van-field
              v-model="orderDetailsObj.orderFormDelivery.deliveryCompanyName"
              name="快递公司"
              label="快递公司"
              placeholder="快递公司"
            />

            <van-field
              v-model="orderDetailsObj.orderFormDelivery.deliverySerialNum"
              name="快递单号"
              label="快递单号"
              placeholder="快递单号"
            />
          </template>
        </van-form>

        <van-divider dashed>物流信息</van-divider>

        <van-steps
          direction="vertical"
          :active="0"
          v-if="logisticsList.length > 0"
        >
          <van-step v-for="(item, index) in logisticsList" :key="index">
            <h3>{{ item.status }}</h3>
            <p>{{ item.time }}</p>
          </van-step>
        </van-steps>

        <el-empty description="暂无物流信息" v-else></el-empty>
      </div>
    </van-popup>

    <!-- 创建二维码 -->
    <CREATEQRCODE ref="createQrcode" :orderId="this.orderId"></CREATEQRCODE>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { mapGetters, mapState } from "vuex";
// import Order from "@/components/order";
import Service from "@/pages/pc/serive-1";
import { detail } from "@/api/order";

import { queryLogisticsInfo } from "@/api/order";

import CREATEQRCODE from "../mobile/order/createQrcode";

export default {
  components: {
    CREATEQRCODE,
    Service,
    Pagination,
  },
  data() {
    return {
      logisticsList: [],
      logisticsStatus: false,

      CS: {
        "text-align": "center", //文本居中
        width: "80%",
        "word-break": "break-all", //过长时自动换行
      },
      LS: {
        //  color: '#000',文字的颜色
        "text-align": "center",
        height: "40px",
      },
      orderStatusList: [
        {
          statusDelivery: undefined,
          orderLabel: "全部",
        },
        {
          statusDelivery: 0,
          orderLabel: "等待处理",
        },
        {
          statusDelivery: 1,
          orderLabel: "正在处理",
        },
        {
          statusDelivery: 2,
          orderLabel: "交易成功",
        },
        {
          statusDelivery: 3,
          orderLabel: "已退款",
        },
      ],
      orderId: null,
      dialogVisible: false,
      orderDetailsObj: {},
      orderDetailsDialog: false,
      activeName: undefined,
      number: [1, 1, 1, 1],
      lists: [],
      reqData: {},
      // 查询参数
      queryParams: {
        rechargeAccount: undefined,
        orderNum: undefined,
        pageNum: 1,
        pageSize: 10,
        statusDelivery: undefined,
      },
      total: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      list: "orderList",
    }),
    ...mapState({
      isUpdate: (state) => state.order.isUpdate,
      orderDetail: (state) => state.order.orderDetail,
      orderTotal: (state) => state.order.orderTotal,
    }),
  },
  watch: {
    isUpdate() {
      this.loadData(this.reqData);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("order/list", this.queryParams).then(() => {
        this.lists = this.list;
        this.total = this.orderTotal;
        // this.$forceUpdate();
      });
    },

    /**
     * 卡密转二维码
     */
    cardToQrCode() {
      this.orderDetailsDialog = false;

      this.$refs.createQrcode.hadldeOpen();
    },

    /**
     * 获取物流信息
     */
    getLogisticsInfo(data) {
      this.logisticsList = [];
      this.orderDetailsObj = data;

      let id = {
        id: data.id,
      };

      new Promise((resolve, rejust) => {
        queryLogisticsInfo(id)
          .then((res) => {
            this.logisticsList = res.result.list;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });

      this.logisticsStatus = true;
    },

    handleClick(statusDelivery) {
      this.lists = [];
      // 更新查询参数
      this.queryParams.statusDelivery = statusDelivery;
      this.queryParams.pageNum = 1;
      // 重新加载数据
      this.loadData();
    },

    /**
     * 条件查询
     */
    queryCriteria() {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = 10;
      this.loadData();
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum;
      this.loadData();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParams.pageSize = val;
      this.loadData();
    },

    //订单详情
    orderDetails(index, row) {
      this.orderId = row.id;
      //主站商品列表
      new Promise((resolve, rejust) => {
        let data = {
          id: row.id,
        };
        detail(data)
          .then((res) => {
            this.orderDetailsObj = res.result;
            this.orderDetailsDialog = true;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 一键复制全部卡密
     */
    allCopying() {
      // 将 cardsList 转换为字符串，处理 cardNum 和 cardPwd 为 null 或空字符串的情况
      const textToCopy = this.orderDetailsObj.cardsList
        .map((card) => {
          const cardNumPart = card.cardNum ? `卡号: ${card.cardNum}` : "";
          const cardPwdPart = card.cardPwd ? `, 密码: ${card.cardPwd}` : "";
          return cardNumPart + cardPwdPart;
        })
        .filter((text) => text !== "") // 过滤掉空字符串
        .join("\n");

      // 使用 Clipboard API 复制到剪贴板
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.$message({
            message: "复制成功!",
            type: "info",
            duration: 5 * 1000,
          });
        })
        .catch((err) => {
          console.error("复制失败:", err);
        });
    },

    /**
     * 确认收货
     */
    confirmReceived(id) {
      this.$store.dispatch("order/confirmReceived", { id: id });
      // this.$forceUpdate();
    },

    /**
     * 申请售后
     */
    applySh(id) {
      this.orderId = id;
      this.dialogVisible = !this.dialogVisible;
      // this.$router.push({ path: "serive_detail", query: { orderid: id } });
      // this.$forceUpdate();
    },

    /**
     * 确认付款
     */
    confirmPayment(item) {
      this.$router.push({
        path: "/confirmPayment",
        query: { orderId: item.id },
      });
    },

    /**
     * 跳转到商品详情
     */
    toProductDetails(id) {
      this.$router.push(`/pgoodsDetail/${id}`);
    },

    /**
     * 关闭售后详情弹框
     */
    clickClose() {
      console.log("-==============================================-");
      this.dialogVisible = !this.dialogVisible;
    },
  },
};
</script>
<style>
/* 订单 */

.seller-mod__container___zFAFV {
  white-space: nowrap;
}

.ml-mod__container___2DOxT:after {
  display: table;
  content: " ";
  font-size: 0;
  height: 0;
  clear: both;
}

.ml-mod__media___2sZrj {
  float: left;
}

.production-mod__production___123Ax {
  text-align: left;
}

.production-mod__pic___G8alD {
  display: block;
  overflow: hidden;
}

.production-mod__pic___G8alD img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.order_product_type {
  color: #9e9e9e;
  margin-right: 8px;
  line-height: 16px;
}

.price-mod__price___3_8Zs {
  font-family: verdana;
  font-style: normal;
}

.bought-table-mod__table___AnaXt {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: #3c3c3c;
  table-layout: fixed;
  padding-bottom: 10px;
}

.bought-table-mod__table___AnaXt a {
  color: #3c3c3c;
  text-decoration: none;
  word-break: break-all;
}

.bought-table-mod__table___AnaXt p {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1;
}

.bought-table-mod__table___AnaXt td,
.bought-table-mod__table___AnaXt th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bought-table-mod__table___AnaXt th {
  font-weight: 400;
}

.bought-table-mod__col1___3U5RK {
  width: 38%;
}

.bought-table-mod__col2___224Oh {
  width: 10%;
}

.bought-table-mod__col3___J0oe0 {
  width: 10%;
}

.bought-table-mod__col4___XvKTC,
.bought-table-mod__col5___2kktP {
  width: 12%;
}

.bought-table-mod__col6___1KqCQ {
  width: 11%;
}

.bought-table-mod__col7___PpB_p {
  width: 12%;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__head___2vnqo {
  background: #eaf8ff;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__head___2vnqo td {
  border: none;
}

.bought-wrapper-mod__table___3xFFM
  .bought-wrapper-mod__head___2vnqo
  .bought-wrapper-mod__head-info-cell___29cDO {
  text-align: left;
}

.bought-wrapper-mod__table___3xFFM td {
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #daf3ff;
  text-align: center;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__no-br___gr7vV,
.bought-wrapper-mod__table___3xFFM td:last-child {
  border-right: none;
}

.bought-wrapper-mod__table___3xFFM .bought-wrapper-mod__no-bt___1e-OX {
  border-top: none;
}

.bought-wrapper-mod__checkbox-label___3Va60 {
  margin-left: 20px;
}

.bought-wrapper-mod__create-time___yNWVS {
  margin-right: 10px;
  font-weight: 700;
}

.bought-wrapper-mod__table___3xFFM
  .bought-wrapper-mod__thead-operations-container___2LwDA {
  padding-right: 20px;
  text-align: right;
}

.bought-wrapper-mod__seller-container___3dAK3 {
  padding: 10px 20px;
  text-align: center;
}

.sol-mod__no-br___36X3g {
  border-right: none !important;
}

.suborder-mod__production___3WebF {
  margin-left: 20px;
}

.suborder-mod__production___3WebF a:hover {
  color: #ff4200;
}

/* .bought-table-mod__table___AnaXt td {
  vertical-align: top;
} */

/* 美化tabs */
.order-tabs {
  padding: 0px 10px;
}

.order-tabs .el-tabs__header {
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;
}

.order-tabs .el-tabs__item {
  color: #606266;
  border: none;
  height: 32px;
  line-height: 32px;
  /* margin-right: 10px; */
  font-size: 14px;
  padding: 0 15px;
}

.order-tabs .el-tabs__item.is-active {
  background-color: #1152f4;
  color: #ffffff;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  /* padding: 12px 16px; */
}

.order-tabs .el-tabs__pane {
  padding: 10px;
}

.order-tabs .el-tabs__nav {
  padding: 0px;
}

.order-tabs .el-tabs__active-bar {
  background-color: transparent !important;
}

.order-tabs .el-col-5 {
  width: 20%;
}

/* 新的 */

.order-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.order-list .order-item {
  margin-bottom: 12px;
}

.order-list .order-item .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38px;
  line-height: 38px;
  background-color: #f8f8f8;

  font-size: 12px;
  padding: 0 20px;
  position: relative;
  color: #919191;
  border-radius: 8px 8px 0 0;
}

.order-list .order-item .top .status {
  margin-right: 50px;
}

.order-list .order-item .top .ordersn,
.order-list .order-item .top .time {
  margin-right: 80px;
}

.order-list .order-item .top .otherordersn span {
  color: #2987e6;
}

.order-list .order-item .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 106px;
  padding: 0px 13px;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  border-radius: 0 0 8px 8px;
}

.order-list .order-item .bottom .goods_img {
  width: 110px;
  /* display: inline-block;
  overflow: hidden;
  vertical-align: top;
  cursor: pointer; */
}

.order-list .order-item .bottom .goods_img img {
  width: 100%;
  height: 100%;
  border: 0;
}

.order-list .order-item .bottom .goods_name {
  width: 400px;
  color: #2d2d2d;
  line-height: 40px;
  margin-left: 10px;
  overflow: hidden;
  cursor: pointer;
  font-size: 14px;
}

.order-list .order-item .bottom .goods_name p {
  display: -webkit-box;
  width: 100%;
  height: 52px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  line-height: 26px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
  white-space: normal;
}

.order-list .order-item .bottom .goods_price {
  width: 200px;
  font-size: 16px;
  /* text-align: center; */
  color: #666;
}

.order-list .order-item .bottom .pay_type {
  width: 100px;
  text-align: center;
  color: #919191;
}

.order-list .order-item .bottom .order-tpl {
  width: 300px;
}

.order-list .order-item .bottom .order-tpl ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.order-list .order-item .bottom .order-tpl ul li .el-button {
  padding: 0 !important;
}

.order-list .order-item .bottom .order-tpl ul li .img-item,
.order-list .order-item .bottom .order-tpl ul li .text-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.order-list .order-item .bottom .order-tpl ul li .img-item > .title,
.order-list .order-item .bottom .order-tpl ul li .text-item > .title {
  display: block;
  min-width: 100px;
  text-align: right;
}

.order-list .order-item .bottom .btns {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
  padding: 30px;
}

.order-list .order-item .bottom .btns .el-button:last-of-type {
  margin-top: 10px;
}

.order_lable {
  font-weight: 700;
  color: #333333;
  font-family: "PingFangSC", "PingFang SC", sans-serif;
  font-size: 14px;
}

.order_lable_name {
  font-size: 14px;
  color: #999999;
}

.order_details_title {
  padding-left: 10px;
  font-size: 15px;
  line-height: 15px;
  color: #303133;
  border-left: 3px solid #1890ff;
  margin-bottom: 18px;
}

.order_product_img {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
</style>

<template>
  <div class="container">
    <div class="cata-container">
      <div class="cata-container-left">
        <div
          class="item"
          v-for="(item, index) in catesList"
          :key="index"
          @click="querSelectedIndex(item.id, index)"
          :class="{ active: item.id == queryParam.categoryPid }"
        >
          <div class="icon-img">
            <el-image class="icon-img" :src="item.prcUrl" lazy></el-image>
          </div>
          <div class="text">{{ item.categoryName }}</div>
        </div>
        <div class="slider" style="top: 0px"></div>
      </div>
      <div class="cata-container-right">
        <div class="cata-nav">
          <div
            class="item"
            v-for="(value, index) in childrenList"
            :key="index"
            @click="toProductList(value.id, index)"
            :class="{ active: value.id == queryParam.categoryId }"
          >
            <div class="item-img-wrap">
              <div class="item-img">
                <img :src="value.prcUrl" lazy />
              </div>
            </div>
            <div class="item-text">{{ value.categoryName }}</div>
          </div>
        </div>

        <div class="cata-list">
          <div class="goodslist-brands">
            <div class="item active">全部</div>
            <!-- <div class="item">其他商品</div> -->
          </div>
          <!---->
          <div
            class="item"
            v-for="(item, k, i) in goodsList"
            :key="i"
            @click="toProductDetails(item)"
          >
            <span class="img">
              <img :src="item.picUrl" style="width: 100%" />
            </span>
            <div class="auto">
              <span class="title">{{ item.productName }}</span>
              <div class="table">
                <div class="table-item wide">
                  <em>商品编号：</em><span>{{ item.id }}</span>
                </div>
                <div class="table-item">
                  <em>库存：</em><span>{{ item.inventoryQuantity }}</span>
                </div>
                <div class="table-item wide">
                  <em>订单耗时：</em><span>暂无</span>
                </div>
                <div class="table-item">
                  <em>限时价：</em>
                  <span style="color: rgb(255, 45, 30)">
                    {{ item.price }}元
                  </span>
                </div>
              </div>
            </div>
            <div class="right">
              <a href="#" class="btn">
                <a-icon
                  type="shopping-cart"
                  style="font-size: 20px; margin-right: 5px"
                />立即购买
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { queryProducCategorytList } from "@/api/index";

export default {
  data() {
    return {
      productClassType: 1,

      childrenList: [],

      catesList: [],
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        goodsId: undefined,
        orderType: undefined,
        categoryId: undefined,
        categoryPid: undefined,
        pageNum: 1,
        pageSize: 10,
        searchKeyword: undefined,
      },

      loadingState: false,
      finished: false,

      isFetching: false, // 是否有正在进行中的请求

      isOpened: false,
    };
  },
  components: {
    // Header,
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productList: (state) => state.index.productList,
      producCategorytList: (state) => state.index.producCategorytList,
    }),
  },
  created() {
    document.title = "商品列表";

    this.queryParam.categoryPid = this.$route.query.categoryPid;
    this.queryParam.categoryId = this.$route.query.categoryId;

    //查询一级分类
    this.queryCategorytList();

    this.getProductList();
  },

  methods: {
    /**
     * 加载更多
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.getProductList();
    },

    //查询商品列表
    getProductList() {
      this.isFetching = true;

      // this.queryParam.searchKeyword = this.$route.query.goodsName,

      this.$store
        .dispatch("index/queryProductList", this.queryParam)
        .then(() => {
          this.goodsList = this.goodsList.concat(this.productList.productList);
          if (this.productList.productList.length == 0) {
            this.finished = true;
          }
          this.loadingState = false;
          this.isFetching = false;
        });
    },

    /**
     * 条件排序查询
     */
    handleQuery(val) {
      this.queryParam.orderType = val;

      this.queryParam.pageNum = 1;

      this.goodsList = [];
      this.finished = false;

      this.loadingState = true;

      this.getProductList();
    },

    /**
     * 根据名称条件查询
     */
    handleQueryByName() {
      this.queryParam.pageNum = 1;

      this.queryParam.goodsId = undefined;
      this.queryParam.orderType = undefined;
      this.queryParam.categoryId = undefined;
      this.queryParam.categoryPid = undefined;

      this.goodsList = [];

      this.getProductList();
    },

    /**
     * 点击一级目录
     */
    querSelectedIndex(categoryId, index) {
      if (this.catesList[index].children) {
        this.childrenList = this.catesList[index].children;
      }

      this.queryParam.categoryPid = categoryId;
      this.queryParam.categoryId = undefined;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.finished = false;

      this.loadingState = true;
      this.getProductList();
    },

    /**
     * 点击二级目录
     */
    toProductList(id) {
      this.queryParam.categoryPid = undefined;
      this.queryParam.categoryId = id;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.finished = false;

      this.loadingState = true;
      this.getProductList();
    },

    /**
     * 查询商品分类
     */
    queryCategorytList() {
      queryProducCategorytList({})
        .then((res) => {
          this.catesList = res.result.list;

          this.childrenList = this.catesList[0].children;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          //查询二级分类
          this.queryTwoCategoryList();
        });
    },

    /**
     * 查询二级分类
     */
    queryTwoCategoryList() {
      for (let item of this.catesList) {
        if (item.id == this.queryParam.categoryPid) {
          console.log(item);
          this.childrenList = item.children;
          break;
        }
      }
    },

    /**
     * 一级分类展开
     */
    toggleOpenClose() {
      this.isOpened = !this.isOpened;
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/pgoodsDetail/${v.id}`);
    },
  },
};
</script>
<style scoped>
.cata-top-nav {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 14px;
  border-radius: 20px 20px 0 0;
}

.cata-top-nav .title {
  font-size: 15px;
  text-indent: 18px;
  color: #fff;
  position: relative;
}

.cata-top-nav .title:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 16px;
  border: 2px solid #fff;
}

.cata-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cata-container .cata-container-left {
  position: relative;
  width: 200px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}

.cata-container .cata-container-left .item {
  width: 100%;
  height: 75px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cata-container .cata-container-left .item.active {
  border-left: 2px solid #06f;
  background-color: #e8f3fc;
}

.cata-container .cata-container-left .item .icon-img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 5px;
}

.cata-container .cata-container-left .item .icon-img img {
  width: 100%;
  height: 100%;
}

.cata-container .cata-container-left .item .text {
  font-size: 17px;
  color: #333;
}

.cata-container .cata-container-left .slider {
  width: 100%;
  height: 75px;
  position: absolute;
  transition: top 0.2s ease-in-out;
  z-index: -1;
}

.cata-container .cata-container-left .slider:before {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  height: 100%;
  width: 3px;
}

.cata-container .cata-container-right {
  width: calc(100% - 210px);
}

.cata-container .cata-container-right .cata-nav {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.cata-container .cata-container-right .cata-nav .item {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(221, 221, 221, 0.4);
  cursor: pointer;
}

.cata-container .cata-container-right .cata-nav .item.active {
  border: 1px solid #0066ff;
}

.cata-container .cata-container-right .cata-nav .item .item-img-wrap {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cata-container .cata-container-right .cata-nav .item .item-img-wrap .item-img {
  width: 64px;
  height: 64px;
  border-radius: 74px;
  border: 5px solid #fff;
  box-shadow: 0 5px 15px var(--el-color-primary-light-8);
  overflow: hidden;
}

.cata-container
  .cata-container-right
  .cata-nav
  .item
  .item-img-wrap
  .item-img
  img {
  width: 100%;
  height: 100%;
}

.cata-container .cata-container-right .cata-nav .item .item-text {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #333;
  position: relative;
}

.cata-container .cata-container-right .cata-nav .item .item-text:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  height: 1px;
  background: rgba(221, 221, 221, 0.2);
}

.cata-container .cata-container-right .cata-nav .item.active .item-text {
  color: #fff;
  background: linear-gradient(to left, #0066ff, #5599ff);
}

.cata-container .cata-container-right .cata-nav .item.active .item-text:after {
  height: 0;
}

.cata-container .cata-container-right .cata-list {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.cata-container .cata-container-right .cata-list .item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-radius: 8px;
}

.cata-container .cata-container-right .cata-list .item:hover {
  box-shadow: 0 0 10px #00000014;
}

.cata-container .cata-container-right .cata-list .item + .item {
  border-top: 1px solid #f7f7f7;
}

.cata-container .cata-container-right .cata-list .item .img {
  width: 116px;
  height: 116px;
}

.cata-container .cata-container-right .cata-list .item .auto {
  width: calc(100% - 276px);
}

.cata-container .cata-container-right .cata-list .item .auto .title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #0066ff;
}

.cata-container .cata-container-right .cata-list .item .auto .table {
  margin-top: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.cata-container
  .cata-container-right
  .cata-list
  .item
  .auto
  .table
  .table-item {
  width: 100%;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.cata-container
  .cata-container-right
  .cata-list
  .item
  .auto
  .table
  .table-item.wide {
  font-weight: 700;
}

.cata-container
  .cata-container-right
  .cata-list
  .item
  .auto
  .table
  .table-item
  em {
  display: block;
  font-style: normal;
  width: 74px;
  text-align: justify;
  text-align-last: justify;
}

.cata-container .cata-container-right .cata-list .item .right {
  width: 120px;
}

.cata-container .cata-container-right .cata-list .item .right .btn {
  width: 120px;
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  background: #0066ff;
  border-radius: 5px;
  transition: 0.1s;
}

.cata-container .cata-container-right .cata-list .item .right .btn:hover {
  background: #5599ff;
}

.cata-container .cata-container-right .cata-list .item .right .btn.disabled {
  background: #ccc;
  cursor: not-allowed;
}

.cata-container .cata-container-right .cata-list .item .right .btn .icon {
  width: 16px;
  height: 16px;
  display: flex;
  margin-right: 6px;
}

.cata-container .cata-container-right .cata-list .goodslist-null {
  height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cata-container .cata-container-right .cata-list .goodslist-brands {
  width: 100%;
  height: 50px;
  padding: 0 0 10px;
  background: #fff;
  display: flex;
  align-items: center;
}

.cata-container .cata-container-right .cata-list .goodslist-brands .item {
  padding: 0 14px;
  width: fit-content;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  background: #bbb;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
}

.cata-container
  .cata-container-right
  .cata-list
  .goodslist-brands
  .item
  + .item {
  margin-left: 10px;
}

.cata-container .cata-container-right .cata-list .goodslist-brands .item:hover,
.cata-container
  .cata-container-right
  .cata-list
  .goodslist-brands
  .item.active {
  background: #0066ff;
}
</style>
